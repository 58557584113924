<template>
  <v-col justify="center">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    
    <v-row justify="center" class="col-md-10 pb-6">
      <h1 class="text-h3 text-md-h2 text-lg-h2 text-xl-h2">Ever wondered what sound looks like?</h1>
    </v-row>
    <v-row justify="center" class="col-md-8 pb-6">
      <h3 class="text-h4 text-md-h3 text-lg-h3 text-xl-h3">Now you don't have to.</h3></v-row>
    <v-row justify="center" class="col-md-8 pb-6">
      <p>
        VisualMusic is web3 app that shows you what you're hearing using nothing more than the sound itself.
        From watching the dancing shapes of custom tracks to displaying the secret codes embedded in your <b><em>own</em></b> Visual Music, the possibilities are endless.</p>
    </v-row>
    <v-row justify="center" class="col-md-8" >
        <p>Check out the <a href="/demo">DEMO</a> page to see how it works.</p>
    </v-row>
    <v-row justify="center" class="col-md-8">
        <span>To access the app, <a href="/connect">connect your Wallet</a></span>
    </v-row>

    <!--
    <v-row justify="center" style="height: 80px">
      <v-btn class="ml-6">
        <router-link to="/Connect">Connectweb3</router-link>
      </v-btn>
    </v-row>
    -->
  </v-col>
</template>

<script>

import api from '@/api';

export default {
  name: 'Home',
  props: ['walletData'],
  metamask_cond: 0,
  ethereum_account: 0,

  data() {
    return {
      result: '',
      isAdmin: false,
    };
  },

  methods: {
    // connectMetaMask() {
    //   api.connectMetaMask().then((val)=> this.ethereum_account = val);
    // },
    handleAccountChange(accounts){

    }
  },

  created: function(){

  },

  /*created() {
    api.access()
      .then(api.claims)
      .then((claims) => { this.isAdmin = claims.role === 'admin'; })
      .catch(() => this.$router.push('/login'));
  },*/
}

</script>
